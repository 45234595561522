<template>
    <div style="padding: 40px 60px;" v-loading="info == null">
        <div v-if="info != null">
            <h1>用户信息：</h1>
            <p class="attr"><span class="attr-name">姓名</span><span class="attr-val">{{ info.cn }}</span></p>
            <p class="attr"><span class="attr-name">账号</span><span class="attr-val">{{ info.uid }}</span></p>
            <p class="attr"><span class="attr-name">UID Number</span><span class="attr-val">{{ info.uidNumber }}</span></p>
            <p class="attr"><span class="attr-name">Login Shell</span><span class="attr-val">{{ info.loginShell }}</span></p>
            <p class="attr"><span class="attr-name">邮箱</span><span class="attr-val">{{ info.mail }}</span></p>
            <p class="attr"><span class="attr-name">手机号</span><span class="attr-val">{{ info.telephoneNumber }}</span></p>
        </div>
    </div>
</template>

<script>
import api from "../api"
export default {
    data: () => ({
        info: null
    }),
    mounted() {
        api.getMyInfo().then(res => {
            if (res.code == 0) {
                this.info = res.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch(err => {
            this.$message.error(err.toString());
            console.error(err);
        });
    }
}
</script>

<style scoped>
.attr {
    margin-bottom: 15px;
}

.attr .attr-name {
    display: inline-block;
    width: 170px;
    text-align: right;
    margin-right: 35px;
}

.attr .attr-name::after {
    content: ':';
    margin-left: 10px;
}
</style>